<template>
  <div>
    <p class="text-orange">
      PS：已发起变更的结算单(正在审批中或者是草稿状态)，将不在当前列表内显示，请到变更列表中编辑查看！
    </p>
    <Form label-position="top">
      <Row :gutter="8">
        <i-col span="24">
          <FormItem label="选择变更结算单">
            <i-select  size="small" v-model="chosseStatementId" filterable clearable >
              <i-option
                v-for="statement in list"
                :key="statement.id"
                :value="statement.id"
                >{{ statement.name }}</i-option
              >
            </i-select>
          </FormItem>
        </i-col>
        <i-col span="24">
          <FormItem>
            <div
              class="p-t-10"
              v-if="chosseStatementId && chosseStatementId !== 0"
            >
              <Row>
                <i-col span="8" class="title">结算单编号 </i-col>
                <i-col span="16">{{ statement.code }}</i-col>
              </Row>
              <Row>
                <i-col span="8"  class="title">客户 </i-col>
                <i-col span="16">{{ statement.advertiserName }}</i-col>
              </Row>
              <Row>
                <i-col span="8"  class="title">总额 </i-col>
                <i-col span="16">{{ formatMoney(statement.amount) }}</i-col>
              </Row>
              <Row>
                <i-col span="8" class="title">结算单开始日期 </i-col>
                <i-col span="16">{{ statement.startDate }}</i-col>
              </Row>
              <Row>
                <i-col span="8" class="title">结算单结束日期 </i-col>
                <i-col span="16">{{ statement.endDate }}</i-col>
              </Row>
            </div>
          </FormItem>
        </i-col>
      </Row>

      <FormItem class="text-right">
        <Button
          type="success"
          size="small"
          v-show="!isSubmit"
          @click="handleCreateContractChange"
          >创建结算单变更</Button
        >
      </FormItem>
    </Form>
  </div>
</template>

<script>
import { getStatementPage } from '@/api/scp/statement'
import { getStatementChangingVersionId } from '@/api/scp/statementChange'
import { toMoney } from '@/utils/wnumb_own'

export default {
  data () {
    return {
      list: [],
      query: { pageNumber: 1, pageSize: 100 },
      chosseStatementId: 0,
      statement: {}
    }
  },
  methods: {
    getPage () {
      this.query.userId = this.$store.getters.token.userInfo.userId
      this.query.statusList = JSON.stringify([5])
      this.query.isChanging = false

      getStatementPage(this.query).then((res) => {
        this.list = res.list
      })
    },
    handleCreateContractChange () {
      getStatementChangingVersionId({
        statementId: this.chosseStatementId
      }).then((res) => {
        if (res && !res.errcode) {
          this.chosseStatementId = 0
          this.getPage()
          this.$Notice.success({ desc: '结算单变更创建成功！' })

          this.$emit('on-create', res.statementId, res.versionId)
        }
      })
    },
    formatMoney (money) {
      return toMoney(money || 0)
    }
  },
  computed: {
    isSubmit () {
      return (
        this.chosseStatementId === 0 || this.chosseStatementId === undefined
      )
    },
    updateTime () {
      return this.$store.state.contract.beginUpdate
    }
  },
  created () {
    this.getPage()
  },
  watch: {
    chosseStatementId (val) {
      if (val && val !== 0) {
        this.statement = this.list.find((x) => x.id === val)
      } else {
        this.statement = {}
      }
    },
    updateTime () {
      this.getPage()
    }
  }
}
</script>
